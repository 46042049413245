<template>
  <!-- <teleport to="body"> -->
  <v-dialog
    v-model="isVisible"
    :scrollable="scrollable"
    :width="width"
    :height="height"
    :fullscreen="isFullscreen"
    max-width="90%"
    max-height="90%"
    :persistent="isPersistent"
  >
    <template v-for="(slot, name) in $scopedSlots" v-slot:[name]="item">
      <slot :name="name" v-bind="item"> </slot>
    </template>
    <!-- default button dialog -->
    <template
      v-if="!$scopedSlots.activator && !isExternalActivator"
      v-slot:activator="{ on, attrs }"
    >
      <v-btn
        elevation="3"
        v-show="isButtonVisible"
        :color="colorButtonOpen"
        :class="classButtonOpen"
        dark
        width="120"
        v-bind="attrs"
        v-on="on"
        @click="$emit('onDialogOpen')"
        :small="isSmallButtonOpen"
      >
        <v-icon v-if="isButtonIcon" left> {{ buttonIcon }} </v-icon>
        {{ textButtonOpen }}
      </v-btn>
    </template>

    <v-card style="width: 500px" :height="cardHeight">
      <slot name="dialogHeader"> </slot>
      <v-card-title v-if="isDialogClose" style="height: 45px">
        <v-spacer></v-spacer>
        <v-btn icon @click="onDialogClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <slot v-if="!loading" name="customDialogContent"> </slot>

      <!-- grey lighten-3 -->
      <v-card-text class="mt-3" :style="`position: relative;`">
        <!-- <transition name="trans__dialog__content" v-if="!loading"> -->
        <slot v-if="!loading" name="dialogContent"> </slot>
        <!-- </transition> -->
        <div
          v-else
          class="d-flex align-center justify-center loading"
          style="height: 55vh"
        >
          <v-skeleton-loader
            style="width: 95%"
            type="card,list-item-two-line, divider, actions"
          ></v-skeleton-loader>
        </div>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions class="px-4 py-2" v-if="isCloseBtn">
        <v-spacer></v-spacer>
        <slot v-if="!loading" name="dialogButton"> </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- </teleport> -->
</template>

<script>
export default {
  props: {
    // height for skeleton loader
    cardHeight: {
      type: String,
      required: false,
      default: "",
    },

    isExternalActivator: {
      type: Boolean,
      required: false,
      default: false,
    },
    isPersistent: {
      type: Boolean,
      required: false,
      default: true,
    },
    isDialogClose: {
      type: Boolean,
      required: false,
      default: false,
    },
    width: {
      type: String,
      required: false,
      default: "auto",
    },
    height: {
      type: String,
      required: false,
      default: "unset",
    },
    textTitle: {
      type: String,
      required: false,
      default: "",
    },
    textButtonOpen: {
      type: String,
      required: false,
      default: "Buka Dialog",
    },
    colorButtonOpen: {
      type: String,
      required: false,
      default: "primary",
    },
    classButtonOpen: {
      type: String,
      required: false,
      default: "text-capitalize",
    },
    isButtonIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    buttonIcon: {
      type: String,
      required: false,
      default: "",
    },
    isSmallButtonOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
    isFullscreen: {
      type: Boolean,
      required: false,
      default: false,
    },
    textButtonClose: {
      type: String,
      required: false,
      default: "Batal",
    },
    isButtonClose: {
      type: Boolean,
      required: false,
      default: false,
    },
    scrollable: {
      type: Boolean,
      required: false,
      default: true,
    },
    isCloseBtn: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: ["onDialogOpen", "onDialogClose"],

  data: () => ({
    isVisible: false,
    isButtonVisible: true,
    loading: false,
  }),
  methods: {
    onDialogClose() {
      this.isVisible = false;
      this.$emit("onDialogClose");
    },
    loadData(mode) {
      switch (mode) {
        case "init":
          this.loading = true;
          // this.scrollable = false;
          break;

        case "done":
          this.loading = false;
          // this.scrollable = true;
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.trans__dialog__content-enter-active {
  animation: modal 0.3s ease-out;
}
.trans__dialog__content-leave-active {
  animation: modal 0.3s ease-in;
}
.loading {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
</style>
